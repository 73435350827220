<template>
  <v-container class="timeslot-selector fill-height mt-1">
    <v-layout fill-height column>
      <div class="d-flex flex-column justify-center mb-6 align-center">
        <ebsn-meta
          class="font-weight-bold grey--text text--darken-1 text-center ma-4 mb-4"
          :target="category"
          path="category_info.TITLE"
          tag="h2"
        />
        <ebsn-meta
          :target="category"
          path="category_info.DESCRIPTION"
          tag="div"
        />
        <category-block
          :target="category"
          position="position1"
          class="category-block category-block-1"
          :container="false"
        />
        <div class="selector-subtitle">
          <p class="mb-2 text-center">
            <img
              :width="30"
              class="service-image"
              :src="'/services-icons/' + this.serviceImage + '.svg'"
            />
            <strong v-if="cart.shippingAddress.addressType == 'home'">{{
              $t("timeslots.addressSelectedDelivery")
            }}</strong>
            <strong v-else-if="cart.shippingAddress.addressType == 'drive'">{{
              $t("timeslots.addressSelectedDrive")
            }}</strong>
            <strong v-else>{{ $t("timeslots.addressSelectedLocker") }}</strong
            ><br />

            {{
              cart.shippingAddress.addressName +
                " - " +
                $t(
                  "navbar.address." +
                    cart.shippingAddress.addressTypeId +
                    ".list",
                  cart.shippingAddress
                )
            }}
          </p>
        </div>

        <!-- <v-icon small color="primary" right @click="changeAddress">
            $edit
          </v-icon> -->
      </div>

      <!-- <v-divider></v-divider> -->
      <v-tabs
        hide-slider
        icons-and-text
        v-model="tab"
        :height="80"
        center-active
        show-arrows
        v-if="timeslots.length > 0"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="timeslotDay in timeslots"
          :key="timeslotDay.dateIso"
          @click="selectTimeslotDay(timeslotDay)"
        >
          <div
            class="time-div d-flex flex-column align-center justify-space-around"
          >
            <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
              <span class="day-number font-weight-bold">
                {{ $t("timeslots.today") }}
              </span>
            </template>
            <template v-else>
              <span class="day-string">
                {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
              </span>
              <span class="day-number font-weight-bold">
                {{ $dayjs(timeslotDay.dateIso).format("D") }}
              </span>
              <span class="month">
                {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
              </span>
            </template>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs v-else-if="loading === false">
        Non ci sono fasce disponibili per questa zona
      </v-tabs>
      <!-- <v-divider></v-divider> -->
      <div v-if="cart.homeDelivery">
        <v-tabs-items v-model="tab" class="my-4">
          <v-tab-item
            class="timeslot-container"
            v-for="timeslotDay in timeslots"
            :key="timeslotDay.dateIso"
          >
            <div
              class="pa-4 delivery-fee-extended"
              v-if="showExtendedTimeslots(timeslotDay.timeslots)"
            >
              <v-row>
                <v-col class="delivery-fee-extended-title pb-0"
                  ><b>FASCE ESTESE</b></v-col
                ></v-row
              >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-for="timeslot in filteredExtendedTimeslots(
                    timeslotDay.timeslots
                  )"
                  :key="timeslot.timeslotId"
                >
                  <span
                    ><b>{{
                      timeslot.metaData.timeslot_info.TIMESLOT_DESCRIPTION
                    }}</b></span
                  >
                  <v-btn
                    class="timeslot-button tb"
                    :disabled="disabled"
                    block
                    large
                    depressed
                    @click="selectTimeslot(timeslot)"
                    v-bind:class="[
                      timeslot.status,
                      timeslot.selected ? 'selected' : ''
                    ]"
                  >
                    <div
                      class="timeslot-btn-content d-flex align-center"
                      :class="
                        cart.homeDelivery
                          ? 'justify-space-between'
                          : 'justify-center'
                      "
                    >
                      <span class="timeslot-name">{{ timeslot.name }}</span>
                      <template
                        v-if="cart.homeDelivery && timeslot.deliveryFeeId"
                      >
                        <span
                          v-if="
                            cart.homeDelivery && timeslot.deliveryFeeId === 7
                          "
                          class="delivery-fee promo-delivery-fee"
                        >
                          <small class="d-block promo-title">PROMO</small>
                          <span class="promo-val mt-1">{{
                            $n(bestFee(timeslot.deliveryFeeRows), "currency")
                          }}</span>
                        </span>
                        <span
                          v-else-if="
                            cart.homeDelivery && cart.deliveryFee != 0 &&
                              (timeslot.deliveryFeeId === 6 ||
                                timeslot.deliveryFeeId === 5 )
                          "
                          class="delivery-fee"
                        >
                          <small class="d-block promo-title"
                            >A PARTIRE DA</small
                          >
                          <span class="promo-val mt-1">{{
                            $n(bestFee(timeslot.deliveryFeeRows), "currency")
                          }}</span>
                        </span>
                        <span
                          v-else-if="
                            cart.homeDelivery &&
                              (
                                timeslot.deliveryFeeId === 10 ||
                                timeslot.deliveryFeeId === 11)
                          "
                          class="delivery-fee nopromo-delivery-fee"
                        >
                        <small class="d-block promo-title">PROMO</small>
                        <span class="promo-val mt-1">{{
                          $n(bestFee(timeslot.deliveryFeeRows), "currency")
                        }}</span>
                        </span>
                        <span
                          v-else-if="cart.deliveryFee == 0"
                          class="delivery-fee promo-delivery-fee"
                        >
                          GRATIS
                        </span>
                      </template>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="tab" class="my-4">
          <v-tab-item
            class="timeslot-container"
            v-for="timeslotDay in timeslots"
            :key="timeslotDay.dateIso"
          >
            <div class="pa-4 delivery-fee-standard">
              <v-row>
                <v-col class="delivery-fee-standard-title pb-0"
                  ><b>FASCE STANDARD</b></v-col
                ></v-row
              >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-for="timeslot in filteredStandardTimeslots(
                    timeslotDay.timeslots
                  )"
                  :key="timeslot.timeslotId"
                >
                  <v-btn
                    class="timeslot-button tb1"
                    :disabled="disabled"
                    block
                    large
                    depressed
                    @click="selectTimeslot(timeslot)"
                    v-bind:class="[
                      timeslot.status,
                      timeslot.selected ? 'selected' : ''
                    ]"
                  >
                    <div
                      class="timeslot-btn-content d-flex align-center"
                      :class="
                        cart.homeDelivery
                          ? 'justify-space-between'
                          : 'justify-center'
                      "
                    >
                      <span class="timeslot-name">{{ timeslot.name }}</span>
                      <template
                        v-if="cart.homeDelivery && timeslot.deliveryFeeId"
                      >
                        <!-- {{ cart.homeDelivery }} - {{ cart.deliveryFee }} -
                    {{ bestFee(timeslot.deliveryFeeRows, true) }} -->
                        <span
                          v-if="cart.deliveryFee == 0"
                          class="delivery-fee promo-delivery-fee"
                        >
                          GRATIS
                        </span>
                        <span
                          v-else-if="
                            cart.homeDelivery && timeslot.deliveryFeeId === 7
                          "
                          class="delivery-fee promo-delivery-fee"
                        >
                          <small class="d-block promo-title">PROMO</small>
                          <span class="promo-val mt-1">{{
                            $n(bestFee(timeslot.deliveryFeeRows), "currency")
                          }}</span>
                        </span>
                        <span
                          v-else-if="
                            cart.homeDelivery &&
                              (timeslot.deliveryFeeId === 6 ||
                                timeslot.deliveryFeeId === 5)
                          "
                          class="delivery-fee"
                        >
                          <small class="d-block promo-title"
                            >A PARTIRE DA</small
                          >
                          <span class="promo-val mt-1">{{
                            $n(bestFee(timeslot.deliveryFeeRows), "currency")
                          }}</span>
                        </span>
                      </template>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-else>
        <v-tabs-items v-model="tab" class="my-4">
          <v-tab-item
            class="timeslot-container"
            v-for="timeslotDay in timeslots"
            :key="timeslotDay.dateIso"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-for="timeslot in timeslotDay.timeslots"
                :key="timeslot.timeslotId"
              >
                <v-btn
                  class="timeslot-button tb"
                  :disabled="disabled"
                  block
                  large
                  depressed
                  @click="selectTimeslot(timeslot)"
                  v-bind:class="[
                    timeslot.status,
                    timeslot.selected ? 'selected' : ''
                  ]"
                >
                  <div
                    class="timeslot-btn-content d-flex align-center"
                    :class="
                      cart.homeDelivery
                        ? 'justify-space-between'
                        : 'justify-center'
                    "
                  >
                    <span class="timeslot-name">{{ timeslot.name }}</span>
                    <template
                      v-if="cart.homeDelivery && timeslot.deliveryFeeId"
                    >
                      <!-- {{ cart.homeDelivery }} - {{ cart.deliveryFee }} -
                    {{ bestFee(timeslot.deliveryFeeRows, true) }} -->
                      <span
                        v-if="cart.deliveryFee == 0"
                        class="delivery-fee promo-delivery-fee"
                      >
                        GRATIS
                      </span>
                      <span
                        v-else-if="cart.homeDelivery"
                        class="delivery-fee promo-delivery-fee"
                      >
                        <small class="d-block promo-title">PROMO</small>
                        <span class="promo-val mt-1">{{
                          $n(bestFee(timeslot.deliveryFeeRows), "currency")
                        }}</span>
                      </span>
                      <span
                        v-else-if="
                          cart.homeDelivery &&
                            (timeslot.deliveryFeeId === 6 ||
                              timeslot.deliveryFeeId === 5)
                        "
                        class="delivery-fee"
                      >
                        <small class="d-block promo-title">A PARTIRE DA</small>
                        <span class="promo-val mt-1">{{
                          $n(bestFee(timeslot.deliveryFeeRows), "currency")
                        }}</span>
                      </span>
                    </template>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-divider></v-divider>
      <div
        v-html="'Legenda'"
        class="px-2 text-uppercase primary--text text--lighten-1"
      />
      <v-row class="legend align-center" no-gutters>
        <v-col cols="4" sm="4" class="py-0">
          <div class="timeslot-legend empty">
            <span>{{ $t("timeslots.slotAttribute.empty") }}</span>
          </div>
        </v-col>
        <v-col cols="4" sm="4" class="py-0">
          <div class="timeslot-legend full">
            <span>{{ $t("timeslots.slotAttribute.full") }}</span>
          </div>
        </v-col>
        <v-col cols="4" sm="4" class="py-">
          <div class="timeslot-legend selected">
            <span>{{ $t("timeslots.slotAttribute.selected") }}</span>
          </div>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
      </v-row>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.timeslot-selector {
  .timeslot-button.v-btn:not(.v-btn--round).v-size--large {
    padding-right: 0px;
    .v-btn__content {
      height: 100%;
      width: 100%;
      .timeslot-btn-content {
        width: 100%;
        font-weight: normal;
        .timeslot-name {
          width: 20%;
        }
      }
      .delivery-fee {
        .promo-title {
          margin-top: 4px;
          margin-bottom: -4px;
        }
        .promo-val {
          margin-top: -2px;
        }
        width: 100px;
        border-left: 1px solid var(--v-grey-lighten2);
        // padding-left: 12px;
        font-weight: bold;
        height: 44px;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &.promo-delivery-fee {
          border: 1px solid #de1e1e !important;
          color: $primary-promo !important;
          line-height: 1.2;
          .promo-timeslot {
            font-size: 13px;
          }
          .gift-cert-descr {
            font-size: 15px;
          }
        }
        &.nopromo-delivery-fee {
          border: 1px solid #de1e1e;
          color: #de1e1e;
        }
      }
    }
    &.selected {
      .delivery-fee {
        &.promo-delivery-fee,
        &.nopromo-delivery-fee {
          color: var(--v-base-white);
          border: none;
          border-left: 1px solid white;
        }
        &.promo-delivery-fee {
          background-color: $white !important;
        }
      }
    }
  }
  .service-image {
    vertical-align: middle;
    margin: 10px;
  }
  .delivery-fee-standard {
    width: 100%;
    border: 1px solid var(--v-primary-base);
    border-radius: 5px;
    .delivery-fee-standard-title {
      b {
        color: var(--v-primary-base) !important;
      }
    }
  }
  .delivery-fee-extended {
    background-color: var(--v-secondary-lighten2);
    width: 100%;
    border: 1px var(--v-secondary-lighten2);
    border-radius: 5px;
    .delivery-fee-extended-title {
      b {
        color: #da1f24 !important;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import cartInfo from "~/mixins/cartInfo";

import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import get from "lodash/get";
import { mapState } from "vuex";

export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      timeslots: [],
      tab: 0,
      selectedTimeslotDay: {},
      disabled: false,
      category: {},
      loading: null,
      standardTimeslots: [],
      showExtendend: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    isDelivery() {
      return this.cart.shippingAddress.addressType == "home";
    },
    deliveryFee() {
      const totalVariation = this.cart.orderAdjustmentSummary
        ? this.cart.orderAdjustmentSummary.TYPE_SHIPPING_TOTAL.totalVariation
        : null;
      return this.cart.deliveryFee + totalVariation;
    },
    serviceImage() {
      let serviceId = this.cart.shippingAddress.deliveryServiceId;
      if (serviceId === 1) {
        return "clicca-e-ritira";
      } else if (serviceId === 2) {
        return "consegna-a-casa";
      } else return "locker";
    }
  },
  methods: {
    getTimeslotGiftCertificate(timeslot) {
      if (this.cart.totalPrice < 20) {
        // va fatto scendere il minimo di acquisto
        this.bestFee(timeslot.deliveryFeeRows);
      } else {
        return get(timeslot, "userGiftCertificates[0].giftCertificate");
      }
    },
    getTimeslotGiftCertificateProposal(timeslot) {
      if (this.cart.totalPrice < 20) {
        // va fatto scendere il minimo di acquisto
        this.bestFee(timeslot.deliveryFeeRows);
      } else {
        return get(
          timeslot,
          "userGiftCertificates[0].giftCertificate.categoryProposal"
        );
      }
    },
    openAddressSelector() {
      this.$emit("submit", "setAddress");
    },
    bestFee(fee, hasPromo) {
      //verificare il punto 6.c
      if (!hasPromo) {
        if (this.cart.totalPrice < 20) {
          return fee[0].fee;
        } else {
          for (let i = 0; i < fee.length; i++) {
            if (this.cart.totalPrice < fee[i].range) {
              return fee[i].fee;
            } else {
              if (fee[i + 1]) {
                if (this.cart.totalPrice < fee[i + 1].fee) {
                  return fee[i + 1].fee;
                }
              } else {
                return fee[i];
              }
            }
          }
        }
      } else {
        return fee[fee.length - 1].fee;
      }
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      if (timeslot.active < 1) {
        return;
      }
      this.disabled = true;
      let data = await this.$store.dispatch("cart/setTimeslot", {
        dateIso: this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },

    async fetchTimeslots() {
      let vm = this;
      vm.loading = true;
      let timeslots = await DeliveryService.getTimeslotList(false);

      for (let i = 0; i < timeslots.length; i++) {
        if (timeslots[i].timeslots.length > 0) {
          vm.timeslots.push(timeslots[i]);
        }
      }
      // if (vm.timeslots.length > 1 && vm.timeslots[0].timeslots.length == 0) {
      //   vm.timeslots.push();
      // }

      for (let i = 0; i < vm.timeslots.length; i++) {
        if (vm.timeslots[i].dateIso == vm.cart.timeslot.date) {
          vm.tab = i;
          this.selectedTimeslotDay = vm.timeslots[i];
          break;
        }
      }
      vm.loading = false;
      // if (!vm.selectedTimeslotDay.dateIso) {
      //   vm.selectTimeslotDay(vm.timeslots[0]);
      // }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    },
    filteredExtendedTimeslots(timeslots) {
      return timeslots.filter(timeslot => {
        return (
          timeslot.metaData &&
          timeslot.metaData.timeslot_info &&
          timeslot.metaData.timeslot_info.EXPAND == true
        );
      });
    },
    filteredStandardTimeslots(timeslots) {
      return timeslots.filter(timeslot => {
        return (
          !timeslot.metaData ||
          !timeslot.metaData.timeslot_info ||
          !timeslot.metaData.timeslot_info.EXPAND
        );
      });
    },
    showExtendedTimeslots(timeslots) {
      let res = false;
      for (let k = 0; k < timeslots.length; k++) {
        if (
          timeslots[k].metaData &&
          timeslots[k].metaData.timeslot_info &&
          timeslots[k].metaData.timeslot_info.EXPAND == true
        ) {
          res = true;
          break;
        } else {
          res = false;
        }
      }
      return res;
    }
  },

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
    // this.getDayTabName();
  }
};
</script>
